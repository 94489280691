import { css, useTheme } from '@emotion/react';
import { UserSchema } from 'api/user';
import { User } from 'lucide-react';

export const Avatar = ({
  size = 'default',
  user,
  src,
  ...restProps
}: {
  size?: 'small' | 'default' | 'large';
  src?: string;
  user?: UserSchema;
}) => {
  const theme = useTheme();
  const sizeAvatar = (() => {
    if (size === 'small') return '24px';
    if (size === 'default') return '32px';
    if (size === 'large') return '46px';
  })();

  return (
    <div
      css={css`
        display: inline-block;
        width: ${sizeAvatar};
        height: ${sizeAvatar};
        border-radius: 100%;
        overflow: hidden;
      `}
      {...restProps}
    >
      {user?.avatar ? (
        <img
          src={user.avatar}
          alt="avatar"
          css={css`
            object-fit: contain;
          `}
        />
      ) : src ? (
        <img
          src={src}
          alt="avatar"
          css={css`
            object-fit: contain;
          `}
        />
      ) : (
        <div
          css={css`
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${theme.colors['black-400']};
            visibility: hidden;
          `}
        >
          <User css={css`
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          `} />
        </div>
      )}
    </div>
  );
};
