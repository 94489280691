import { css } from '@emotion/react';
import { Header } from 'components/Header';
import { ReactNode } from 'react';
import { useMedia } from 'react-use';
import { Divider } from 'ui/Divider';

export const Layout = ({ inner }: { inner: ReactNode }) => {
  const isTabletScreen = useMedia('(max-width: 905px)');

  return (
    <div
      css={css`
        margin: 0 auto;
        display: block;
        max-width: 1112px;
        padding: 0 8px;
        width: 100%;
        background: #ffffff;
        min-height: 100vh;
      `}
    >
      <Header />
      {!isTabletScreen && (
        <Divider
          css={css`
            margin-bottom: 32px;
          `}
        />
      )}

      <div
        css={[
          css`
            padding: 0 44px 120px;
          `,
          isTabletScreen &&
            css`
              margin-top: 32px;
              padding: 0 8px 60px;
            `,
        ]}
      >
        {inner}
      </div>
    </div>
  );
};
