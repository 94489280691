import { css, useTheme } from '@emotion/react';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

export const IconButton = ({
  children,
  variant = 'default',
  size = 'default',
  color = 'default',
  disabled = false,
  fill = false,
  ...restProps
}: {
  children: ReactNode;
  disabled?: boolean;
  fill?: boolean;
  color?: 'default' | 'black';
  size?: 'default' | 'large';
  variant?: 'default' | 'outlined';
} & ComponentPropsWithoutRef<'button'>) => {
  const theme = useTheme();

  return (
    <button
      css={css`
        outline: none;
        user-select: none;
        font-family: ${theme.font};
        appearance: none;
        text-decoration: none;
        cursor: pointer;
        border: 1px solid transparent;
        padding: 0;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
        color: inherit;
        vertical-align: middle;
        letter-spacing: 0.0119em;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        display: inline-flex;
        border-radius: 100%;
        padding: 2px;
        transition: 200ms all ease;

        &[data-fill='true'] {
          background-color: #fff;
        }

        &[data-fill='true']:disabled {
          cursor: default;
          background-color: #e5e5e5 !important;
          color: #727272 !important;
        }
        &[data-fill='false']:disabled {
          cursor: default;
        }

        &:not(:disabled) {
          &:hover,
          &:focus {
            background-color: #fff;
            filter: brightness(95%);
          }
        }

        &[data-variant='outlined'] {
          border-radius: 8px;
          box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.08),
            inset 0 0 0 1px rgba(0, 0, 0, 0.06),
            inset 0 -1px 0 rgba(0, 0, 0, 0.12),
            inset 1px 0 0 rgba(0, 0, 0, 0.06),
            inset -1px 0 0 rgba(0, 0, 0, 0.06);
        }

        &[data-size='large'] {
          width: 40px;
          height: 40px;
        }

        &:not(:disabled)[data-color='default']:hover {
          color: #000;
        }

        &[data-color='black'] {
          color: #000;
        }
        &[data-color='default'] {
          color: #7f7f7f;
        }
      `}
      data-variant={variant}
      data-size={size}
      disabled={disabled}
      data-color={color}
      data-fill={fill}
      {...restProps}
    >
      {children}
    </button>
  );
};
