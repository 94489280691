import { QueryClient } from '@tanstack/react-query';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const request = (url: string, options = {}) => {
  const requestUrl = `${import.meta.env.VITE_API_HOST}${url}`;

  return fetch(requestUrl, {
    headers: DEFAULT_HEADERS,
    credentials: 'include',
    cache: 'no-store',
    ...options,
  })
    .then(response => {
      if (!response.ok) {
        return response.text().then(res => {
          const jsonResp = (() => {
            try {
              return JSON.parse(res);
            } catch {
              return null;
            }
          })();

          throw new RequestError(jsonResp, response.status);
        });
      }

      if (response.status === 204) return null;
      return response.json().then(result => {
        return result;
      });
    })
    .catch(e => {
      throw e;
    });
};

request.get = (url: string, options: any = {}) => {
  const { params, ...restOptions } = options;

  return request(url, {
    ...restOptions,
    query: params,
  });
};

request.post = (url: string, body: any, options: any = {}) => {
  const { params, ...restOptions } = options;

  return request(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

request.delete = (url: string, body: any = {}, options: any = {}) => {
  const { params, ...restOptions } = options;

  return request(url, {
    method: 'DELETE',
    body: JSON.stringify(body),
  });
};

request.put = (url: string, body: any = {}, options: any = {}) => {
  const { params, ...restOptions } = options;

  return request(url, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};

request.patch = (url: string, body: any = {}, options: any = {}) => {
  const { params, ...restOptions } = options;

  return request(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry(failureCount, error) {
        if (failureCount > 1) return false;
        return true;
      },
    },
  },
});

export class RequestError extends Error {
  payload: { message: string } | string;
  statusCode: number;

  constructor(payload: { message: string } | string, code: number) {
    super();
    this.name = 'RequestError';
    this.message = 'Cannot fetch data';
    this.payload = payload;
    this.statusCode = code;
  }
}

export default request;
