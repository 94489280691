import { css, useTheme } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

export const Modal = ({
  isOpen,
  onOpenChange,
  children,
  ...restProps
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay
          css={css`
            background-color: rgba(0, 0, 0, 0.44);
            position: fixed;
            inset: 0;
            animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

            @keyframes overlayShow {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          `}
        />
        <Dialog.Content
          css={css`
            display: flex;
            background-color: white;
            border-radius: 6px;
            box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90vw;
            max-width: 880px;
            max-height: 710px;
            height: 90%;
            animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

            &:focus {
              outline: none;
            }

            @keyframes contentShow {
              from {
                opacity: 0;
                transform: translate(-50%, -48%) scale(0.96);
              }
              to {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
              }
            }
          `}
          {...restProps}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
