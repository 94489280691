import { Article } from 'components/Article';
import { ArticleCreation } from 'components/Article/ArticleCreation';
import { BootstrapProvider } from 'components/BootstrapProvider';
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from 'ui/Loader';
import { Layout } from './Layout';
import { ThemeProvider } from './ui/ThemeProvider';

const Previews = lazy(() => import('components/Article/Previews'));

const App = () => {
  const client = new QueryClient();

  return (
    <>
      <QueryClientProvider client={client}>
        <ThemeProvider>
          <BootstrapProvider>
            <BrowserRouter>
              <Layout
                inner={
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Previews />
                          </Suspense>
                        }
                      >
                        <Route path="/drafts" />
                        <Route path="/new" element={<ArticleCreation />}>
                          <Route path="/new/:key" />
                        </Route>
                      </Route>

                      <Route path="/articles/:key" element={<Article />} />
                      <Route path="/draft/:key" element={<Article />} />
                    </Routes>
                  </Suspense>
                }
              />
            </BrowserRouter>
          </BootstrapProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

createRoot(document.getElementById('root')!).render(<App />);
