import { getUserSession } from 'api/user';
import { useCurrentUser } from 'hooks/user';
import { ReactNode } from 'react';
import { useQuery } from 'react-query';

export const BootstrapProvider = ({ children }: { children: ReactNode }) => {
  const [, setUser] = useCurrentUser();

  useQuery(['/user'], getUserSession, {
    onSuccess: data => {
      setUser(data);
    },
  });

  return <>{children}</>;
};
