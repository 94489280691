import { css, useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import { AnyTag, AsTagProps } from 'utils/types';

export const Button = <Tag extends AnyTag = 'button'>(props: AsTagProps<Tag> & ButtonProps) => {
  const theme = useTheme();
  const {
    as: Component = 'button',
    variant,
    children,
    color = 'primary',
    size = 'default',
    startIcon,
    endIcon,
    disabled,
    ...restProps
  } = props;

  return (
    <Component
      css={css`
        outline: none;
        user-select: none;
        appearance: none;
        text-decoration: none;
        cursor: pointer;
        border-radius: 0;
        border: 1px solid transparent;
        padding: 0;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
        color: inherit;
        vertical-align: middle;
        letter-spacing: 0.0119em;
        display: inline-flex;
        align-items: center;
        font-family: ${theme.font};
        font-weight: 500;
        gap: 6px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
        transition: all 200ms ease;
        white-space: nowrap;

        &[data-size='default'] {
          padding: 4px 16px;
        }
        &[data-size='large'] {
          height: 40px;
          min-width: 140px;
        }

        &:hover,
        &:focus {
          filter: brightness(95%);
        }

        &[data-variant='link'][data-color='accent'] {
          color: ${theme.colors['black-800']};
        }

        &[data-variant='filled'][data-color='accent'] {
          border-radius: 43px;
          background-color: ${theme.colors['black-800']};
          color: ${theme.colors.white};
        }
        &[data-variant='filled'][data-color='primary'] {
          font-weight: 700;
          border-radius: 8px;
          background-color: ${theme.colors['mint']};
          color: ${theme.colors.white};
        }
        &:disabled {
          cursor: default;
          background-color: #e5e5e5 !important;
          color: #727272 !important;
        }
      `}
      data-variant={variant}
      data-color={color}
      data-size={size}
      disabled={disabled}
      {...restProps}
    >
      {startIcon && (
        <div
          css={css`
            display: inline-flex;
            align-items: center;
            flex-shrink: 0;
          `}
        >
          {startIcon}
        </div>
      )}
      {children}
      {endIcon && (
        <div
          css={css`
            display: inline-flex;
            align-items: center;
            flex-shrink: 0;
          `}
        >
          {endIcon}
        </div>
      )}
    </Component>
  );
};

export type ButtonProps = {
  variant?: 'link' | 'filled';
  color?: 'primary' | 'accent';
  size?: 'small' | 'default' | 'large';
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
};
