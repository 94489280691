import type { OutputData } from '@editorjs/editorjs/types/data-formats/output-data';
import { css, useTheme } from '@emotion/react';
import { dislikeArticle, getArticle, likeArticle, viewArticle } from 'api/article';
import { RequestError } from 'api/request';
import { Editor } from 'components/Editor';
import { useCurrentUser } from 'hooks/user';
import { ChevronDown, Eye, Heart } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { Avatar } from 'ui/Avatar';
import { Button } from 'ui/Button';
import { Divider } from 'ui/Divider';
import { IconButton } from 'ui/IconButton';
import { Loader } from 'ui/Loader';
import { Typography } from 'ui/Typography';
import { PLATFORM_URL } from 'utils/host';

export const Article = () => {
  const match = useMatch('/articles/:key');
  const [content, setContent] = useState<OutputData | undefined>(undefined);
  const [user] = useCurrentUser();
  const [isLiked, setLike] = useState<boolean>(false);
  const [isDisliked, setDislike] = useState<boolean>(false);
  const theme = useTheme();
  const likeMutation = useMutation(likeArticle);
  const dislikeMutation = useMutation(dislikeArticle);
  const navigate = useNavigate();
  const viewMutation = useMutation(viewArticle);
  const articleQuery = useQuery(
    [`/article/${match?.params.key ?? ''}`],
    () => {
      if (!match?.params.key) return;
      return getArticle(match?.params.key);
    },
    {
      enabled: !!match?.params.key,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        if (!res) return;
        setContent(JSON.parse(res.content));

        const idArticle = match?.params.key?.toString();
        if (!idArticle) {
          throw new Error('ID is empty');
        }
        viewMutation.mutate(idArticle ?? '');
      },
      onError: (err: RequestError) => {
        if (err.statusCode === 403) {
          navigate('/');
        }
      },
    }
  );

  useEffect(() => {
    if (!user?.id) return;

    const hasLike = Boolean(articleQuery.data?.likes?.filter(el => el === user.id).length);
    const hasDislike = Boolean(articleQuery.data?.dislikes?.filter(el => el === user.id).length);

    if (hasLike) {
      setLike(true);
    }

    if (hasDislike) {
      setDislike(true);
    }
  }, [user?.id, articleQuery.data]);

  if (articleQuery.isLoading) return <Loader />;

  return (
    <div
      css={css`
        max-width: 761px;
        width: 100%;
      `}
    >
      <Editor
        initialValue={{
          content: content,
          title: articleQuery.data?.title,
        }}
        readOnly
        css={[
          css`
            .codex-editor__redactor {
              padding-bottom: 0 !important;
            }
          `,
        ]}
      />
      {!articleQuery.data?.is_free && user?.plan !== 'full' && user?.role !== 'admin' && user?.role !== 'editor' && (
        <>
          <div
            css={css`
              width: 100%;
              height: 250px;
              background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
              margin-top: -250px;
              z-index: 1;
              position: relative;
            `}
          >
            {' '}
          </div>
          <div
            css={css`
              margin: 24px auto 0;
              max-width: 600px;
              width: 100%;
            `}
          >
            <Typography
              variant="header2"
              css={css`
                text-align: center;
              `}
              weight="bold"
            >
              Изучай лучшие методы и приемы развития мозга! Знакомься с новыми навыками будущего!
            </Typography>
            <ul
              css={css`
                margin-bottom: 40px;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: block;

                li {
                  line-height: 40px !important;
                }

                & li::marker {
                  color: ${theme.colors.mint};
                }
              `}
            >
              <Typography as="li">сможешь узнать много нового о личности и как стать лучше</Typography>
              <Typography as="li">разовьешь способности и навыки будущего</Typography>
              <Typography as="li">начнешь лучше ладить с людьми и строить отношения</Typography>
            </ul>
            <Button
              css={css`
                width: 250px;
                margin: 0 auto;
                display: flex;
                text-align: center;
                vertical-align: bottom;
              `}
              color={'primary'}
              size="large"
              variant="filled"
              as="a"
              href={(() => {
                if (!user) return `${PLATFORM_URL}/login`;

                return `${PLATFORM_URL}/profile`;
              })()}
            >
              {!user ? 'Зарегистрироваться' : 'Получить полный доступ'}
            </Button>
          </div>
        </>
      )}
      <>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            margin-top: 36px;
            margin-bottom: 12px;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 6px;
            `}
          >
            <IconButton
              disabled={!user}
              onClick={() => {
                setLike(prev => !prev);
                likeMutation.mutate(String(match?.params.key));
                setDislike(false);
              }}
              css={[
                css`
                  padding: 2px 8px;
                  border-radius: 16px;
                  background-color: transparent !important;
                `,
                user &&
                  css`
                    &:hover {
                      color: ${theme.colors.red} !important;
                    }
                  `,
                isLiked &&
                  css`
                    color: ${theme.colors.red} !important;
                  `,
              ]}
            >
              <Heart
                size={20}
                css={[
                  css`
                    margin-right: 8px;
                  `,
                  isLiked &&
                    css`
                      fill: ${theme.colors.red};
                    `,
                ]}
              />
              {(() => {
                const hasLike = Boolean(articleQuery.data?.likes?.filter(el => el === user?.id).length);
                const likesCounter = articleQuery.data?.likes?.length ?? 0;

                if (isLiked && hasLike) return likesCounter;
                if (!isLiked && hasLike) return likesCounter - 1;
                if (isLiked && !hasLike) return likesCounter + 1;

                return likesCounter;
              })()}
            </IconButton>
            <IconButton
              disabled={!user}
              onClick={() => {
                dislikeMutation.mutate(String(match?.params.key));
                setDislike(prev => !prev);
                setLike(false);
              }}
              css={[
                css`
                  padding: 2px 8px;
                  border-radius: 16px;
                  background-color: transparent !important;
                `,
                user &&
                  css`
                    &:hover {
                      color: ${theme.colors.orange} !important;
                    }
                  `,
                isDisliked &&
                  css`
                    color: ${theme.colors.orange} !important;
                  `,
              ]}
            >
              <ChevronDown
                css={[
                  css`
                    margin-right: 8px;
                  `,
                  isDisliked &&
                    css`
                      color: ${theme.colors.orange} !important;
                    `,
                ]}
                size={20}
              />
              {(() => {
                const hasDislike = Boolean(articleQuery.data?.dislikes?.filter(el => el === user?.id).length);
                const dislikesCounter = articleQuery.data?.dislikes?.length ?? 0;

                if (isDisliked && hasDislike) return dislikesCounter;
                if (!isDisliked && hasDislike) return dislikesCounter - 1;
                if (isDisliked && !hasDislike) return dislikesCounter + 1;

                return dislikesCounter;
              })()}
            </IconButton>
          </div>
          <div
            css={css`
              background-color: transparent !important;
              color: ${theme.colors.mist};
              display: flex;
              align-items: center;
            `}
          >
            <Eye
              color="#7f7f7f"
              size={20}
              css={css`
                margin-right: 8px;
              `}
            />
            <Typography
              css={css`
                color: #7f7f7f !important;
              `}
            >
              {articleQuery.data?.views ?? 1}
            </Typography>
          </div>
        </div>
        <Divider
          css={css`
            margin-bottom: 12px;
          `}
        />
        <div
          css={css`
            margin-bottom: 48px;
            display: flex;
          `}
        >
          <Avatar
            css={css`
              box-shadow: 0px 24px 32px -12px rgba(0, 0, 0, 0.12);
              margin-right: 12px;
            `}
            src="/public/images/logo2.png"
            size="large"
          />
          <div>
            <Typography weight="bold">{articleQuery.data?.author_name ?? 'CVCODE'}</Typography>
            <Typography color="gray">Редакция CVCODE</Typography>
          </div>
        </div>
      </>
    </div>
  );
};
