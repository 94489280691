import { css, useTheme } from '@emotion/react';

export const Divider = ({
  orientation = 'horizontal',
  color = 'gray',
  width = 1,
  rounded = false,
  ...restProps
}: {
  orientation?: 'vertical' | 'horizontal';
  color?: 'gray';
  width?: number;
  rounded?: boolean;
}) => {
  const theme = useTheme();
  const dividerColorHEX = (() => {
    if (color === 'gray') return theme.colors.mist;

    return '';
  })();

  return (
    <div
      css={css`
        background-color: ${dividerColorHEX};
        display: block;
        box-sizing: content-box;

        &[data-orientation='horizontal'] {
          height: ${width}px;
          width: 100%;
        }
        &[data-orientation='vertical'] {
          width: ${width}px;
          height: 100%;
        }

        &[data-rounded='true'] {
          border-radius: 8px;
        }
      `}
      data-orientation={orientation}
      data-color={orientation}
      data-rounded={rounded}
      {...restProps}
    />
  );
};
