import request from './request';

export const getUserSession = () => {
  return request.get('/user');
};

export const logout = () => {
  return request.get('/user/sign_out');
};

export type UserSchema = {
  id: string;
  name: string;
  role: 'admin' | 'user' | 'editor' | '';
  plan: 'trial' | 'full';
  avatar: string | undefined;
};
