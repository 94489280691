import { OutputData } from '@editorjs/editorjs';
import { css } from '@emotion/react';
import { default as EditorJS } from '@react-editor-js/client';
import { CUSTOM_THEME, getEditorProps } from 'components/Editor/config';
import { EditorCore } from 'components/Editor/helpers';
import { isEqual } from 'lodash-es';
import { forwardRef, useEffect, useId, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebounce } from 'react-use';

const INITIAL_DATA = {
  time: new Date().getTime(),
  version: '2.27.2',
  blocks: [
    {
      type: 'paragraph',
      data: {
        text: '<a></a>',
      },
    },
  ],
} as OutputData;

export const Editor = forwardRef<
  HTMLDivElement | null,
  {
    readOnly?: boolean;
    initialValue?: {
      content: OutputData | undefined;
      title: string | undefined;
    } | null;
    onChange?: (value: EditorValue) => void;
  }
>(({ onChange, readOnly = false, initialValue: data, ...restProps }, ref) => {
  const id = useId();
  const [editorCore, setEditorCore] = useState<EditorCore | null>(null);
  const [title, setTitle] = useState<string | null>(data?.title ?? '');
  const [isChangingBlock, setChangingBlock] = useState<boolean>(false);
  const [isSavingData, setSavingData] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!isReady || !editorCore || !data?.content) return;
    if (!data.content?.blocks?.length) {
      editorCore.render(INITIAL_DATA);
      return;
    }

    editorCore.render(data.content);
    onChange?.({ ...data, state: 'initialized' });
  }, [isReady, editorCore, data?.content]);

  useEffect(() => {
    if (!data?.title) return;

    setTitle(data.title === 'Без названия' ? title : data.title);
  }, [data?.title]);

  useDebounce(
    () => {
      if (!isSavingData) return;
      if (!editorCore) return;

      editorCore
        .save()
        .then(res => {
          if (isEqual({ title: data?.title ?? '', blocks: data?.content?.blocks }, { title, blocks: res.blocks })) {
            onChange?.({ ...data, state: 'initialized' });
            return;
          }

          onChange?.({ state: 'done', title: title ?? '', content: res });
        })
        .catch(e => {
          onChange?.({ state: 'error' });
          throw new Error(e);
        })
        .finally(() => {
          setSavingData(false);
          setChangingBlock(false);
        });
    },
    1200,
    [isChangingBlock, title]
  );

  return (
    <div {...restProps}>
      <TextareaAutosize
        value={title ?? ''}
        placeholder="Заголовок"
        onChange={e => {
          onChange?.({ state: 'saving' });
          setSavingData(true);
          setTitle(e.target.value);
        }}
        maxRows={readOnly ? 10 : 3}
        readOnly={readOnly}
        autoFocus
        tabIndex={0}
        maxLength={90}
        onKeyDown={e => {
          if (e.code !== 'Enter' && e.code !== 'ArrowDown') return;

          e.stopPropagation();
          e.preventDefault();
        }}
        aria-label="title"
        css={css`
          resize: none;
          outline: 0;
          margin: 0;
          padding: 0;
          border: 0;
          width: 100%;
          font-weight: 800;
          font-size: 36px;
          overflow: hidden;
          background-color: transparent;
          font-family: 'Manrope';

          &::placeholder {
            opacity: 0.2;
          }
        `}
      />
      <EditorJS
        defaultValue={data?.content}
        readOnly={readOnly}
        onReady={() => setReady(true)}
        onChange={() => {
          onChange?.({ state: 'saving' });
          setSavingData(true);
          setChangingBlock(true);
        }}
        onInitialize={core => {
          setEditorCore(core);
          onChange?.({ ...data, state: 'initialized' });
        }}
        holder={id}
        {...getEditorProps()}
      >
        <div
          ref={ref}
          id={id}
          css={[
            CUSTOM_THEME,
            !readOnly &&
              css`
                height: 360px;
              `,
          ]}
        />
      </EditorJS>
    </div>
  );
});

export type EditorValue = {
  title?: string;
  content?: OutputData;
  state: 'initialized' | 'saving' | 'done' | 'error' | null;
};
