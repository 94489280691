import { type ReactNode } from 'react';

import { css, useTheme } from '@emotion/react';
import type { AnyTag, AsTagProps } from 'utils/types';

export function Typography<Tag extends AnyTag = 'p'>(props: AsTagProps<Tag> & TypographyProps) {
  const {
    as: Component = 'p',
    color = 'primary',
    weight = 'normal',
    variant = 'body1',
    children,
    ...restProps
  } = props;

  const theme = useTheme();

  return (
    <Component
      css={css`
        font-family: Manrope;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0;
        text-transform: none;
        text-decoration: none;
        font-stretch: normal;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        transition: all 0.2s ease;

        &[data-weight='medium'] {
          font-weight: 500;
        }

        &[data-weight='semibold'] {
          font-weight: 600;
        }

        &[data-weight='bold'] {
          font-weight: 700;
        }

        &[data-variant='header1'] {
          font-size: 50px;
          line-height: 60px;
        }

        &[data-variant='header2'] {
          font-size: 24px;
          line-height: 32px;
        }

        &[data-variant='header3'] {
          font-size: 20px;
          line-height: 28px;
        }

        &[data-variant='body1'] {
          font-size: 16px;
          line-height: 24px;
        }

        &[data-variant='body2'] {
          font-size: 14px;
          line-height: 20px;
        }

        &[data-color='primary'] {
          color: #1a1a22;
        }

        &[data-color='white'] {
          color: #ffffff;
        }

        &[data-color='gray'] {
          color: #a7a9af;
        }

        &[data-color='green'] {
          color: #00a88b;
        }
        &[data-color='red'] {
          color: ${theme.colors.red};
        }
      `}
      data-weight={weight}
      data-color={color}
      data-variant={variant}
      {...restProps}
    >
      {children}
    </Component>
  );
}

export type TypographyProps = {
  weight?: 'normal' | 'medium' | 'semibold' | 'bold';
  variant?: 'header1' | 'header2' | 'header3' | 'body1' | 'body2';
  color?: 'primary' | 'gray' | 'white' | 'green' | 'red';
  children: ReactNode;
};
