import { css } from '@emotion/react';
import { ArrowDownToLine, RotateCcw, X } from 'lucide-react';
import { ReactNode, useEffect } from 'react';
import { IconButton } from 'ui/IconButton';
import { Typography } from 'ui/Typography';
import { FileContent, useFilePicker } from 'use-file-picker';

export const ImportFile = ({
  multiple = false,
  accept,
  value,
  children,
  onChange,
  onDelete,
  ...restProps
}: {
  value?: FileContent | null;
  multiple?: boolean;
  children?: ReactNode;
  onChange?: (content: FileContent[]) => void;
  onDelete?: () => void;
  accept?: string;
}) => {
  const [openFileSelector, { clear, filesContent, loading }] = useFilePicker({
    accept,
    multiple,
    readAs: 'DataURL',
    maxFileSize: 2.5,
    imageSizeRestrictions: {
      maxHeight: 900,
      maxWidth: 1600,
    },
  });

  useEffect(() => {
    onChange?.(filesContent);
  }, [filesContent]);

  useEffect(() => {
    if (!value) return;

    filesContent.push(value);
  }, [value]);

  if (loading) return <Typography>Загрузка...</Typography>;

  return (
    <div
      css={css`
        display: flex;
        cursor: ${filesContent.length ? 'default' : 'pointer'};
      `}
      onClick={() => {
        if (filesContent.length) return;

        openFileSelector();
      }}
      {...restProps}
    >
      {filesContent.length && !multiple ? (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-right: 4px;
            `}
          >
            <img
              css={css`
                max-width: 60px;
                max-height: 60px;
                object-fit: contain;
                margin-right: 4px;
              `}
              src={filesContent[0]?.content}
            />
            <Typography>{filesContent[0]?.name}</Typography>
          </div>
          <div
            css={css`
              display: flex;
              gap: 4px;
            `}
          >
            <IconButton
              onClick={() => {
                clear();
                openFileSelector();
              }}
            >
              <RotateCcw size={18} />
            </IconButton>
            <IconButton
              onClick={() => {
                clear();
              }}
            >
              <X size={18} />
            </IconButton>
          </div>
        </div>
      ) : (
        <Typography
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ArrowDownToLine
            size={18}
            css={css`
              margin-right: 4px;
            `}
          />
          {children}
        </Typography>
      )}
    </div>
  );
};
