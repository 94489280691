import request from 'api/request';

export const createDraft = (): Promise<Pick<ArticleSchema, 'key'>> => {
  return request.post('/blog/draft', {});
};

export const getArticles = (): Promise<{ articles: ArticlePreviewSchema[] | null }> => {
  return request.get('/blog/articles');
};

export const getDrafts = (): Promise<{ articles: ArticlePreviewSchema[] | null }> => {
  return request.get('/blog/drafts');
};

export const getArticle = (key: string): Promise<ArticleSchema> => {
  return request.get(`/blog/article/${key}`);
};

export const updateArticle = (key: string, body: ArticleUpdateSchema): Promise<ArticleSchema> => {
  return request.put(`/blog/article/${key}`, body);
};

export const deleteArticle = (key: string): Promise<ArticleSchema> => {
  return request.delete(`/blog/article/${key}`);
};

export const likeArticle = (key: string) => {
  return request.put(`/blog/article/${key}/likes`);
};

export const dislikeArticle = (key: string) => {
  return request.put(`/blog/article/${key}/dislikes`);
};

export const viewArticle = (key: string) => {
  return request.put(`/blog/article/${key}/view`);
};

export type ArticlePreviewSchema = Pick<ArticleSchema, 'key' | 'title' | 'image_preview'>;

export type ArticleUpdateSchema = Pick<
  ArticleSchema,
  'key' | 'is_free' | 'content' | 'title' | 'image_preview' | 'status'
>;

export type ArticleSchema = {
  key: string;
  status: 'published' | 'draft';
  is_free: boolean;
  title: string;
  content: string;
  image_preview: string;
  author_name: string;
  likes?: string[];
  dislikes?: string[];
  views?: number;
};
