import { OutputData } from '@editorjs/editorjs';
import { css } from '@emotion/react';
import { ArticleUpdateSchema, createDraft, getArticle, updateArticle } from 'api/article';
import { Editor, EditorValue } from 'components/Editor';
import { ImportFile } from 'components/ImportFile';
import { Archive, CheckCircle2, CircleDollarSign, Eye, Maximize2, Minimize2, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Button } from 'ui/Button';
import { IconButton } from 'ui/IconButton';
import { Loader } from 'ui/Loader';
import { Modal } from 'ui/Modal';
import { Typography } from 'ui/Typography';
import { FileContent } from 'use-file-picker';

export const ArticleCreation = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const match = useMatch('/new/:key');

  const editorRef = useRef<HTMLDivElement | null>(null);
  const [isWideFormat, setWideFormat] = useState<boolean>(false);
  const [isFree, setFree] = useState<boolean>(true);
  const [preview, setPreview] = useState<FileContent | null>(null);
  const [editorInitialValue, setEditorInitialValue] = useState<EditorValue | null>(null);
  const [editorValue, setEditorValue] = useState<EditorValue | null>(null);
  const [articleKey, setArticleKey] = useState<string | null>(match?.params.key ?? null);
  const queryClient = useQueryClient();
  const articleMutaion = useMutation((body: ArticleUpdateSchema) => {
    return updateArticle(articleKey ?? '', body);
  });
  const publishMutaion = useMutation((body: ArticleUpdateSchema) => {
    return updateArticle(articleKey ?? '', body);
  });
  const isMobileScreen = useMedia('(max-width: 940px)');
  useQuery(
    ['/article'],
    () => {
      if (!articleKey) return;
      return getArticle(articleKey);
    },
    {
      enabled: !!articleKey,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        if (!res) return;

        setFree(res.is_free ?? true);
        if (res.image_preview) setPreview({ content: res.image_preview, name: 'Изображение' } as FileContent);
        setEditorInitialValue({
          title: res.title,
          content: JSON.parse(res.content) as OutputData,
          state: 'initialized',
        });
      },
    }
  );

  const createMutation = useMutation(createDraft, {
    onSuccess: res => {
      setArticleKey(res.key);
      navigate(`/new/${res.key}`);
    },
  });

  const closeModal = () => {
    navigate('/');
  };

  useEffect(() => {
    setWideFormat(Boolean(isMobileScreen));
  }, [isMobileScreen]);

  useEffect(() => {
    if (editorValue?.state !== 'done') return;
    if (!articleKey) return;
    const parsedContent = JSON.stringify(editorValue.content);
    const myTtitle = editorValue.title ?? 'Без названия';

    articleMutaion.mutate({
      title: myTtitle,
      key: articleKey,
      is_free: isFree,
      content: parsedContent ?? '{}',
      image_preview: preview?.content ?? '',
      status: 'draft',
    });
  }, [editorValue?.state, preview, isFree]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onOpenChange={isOpen => {
          setModalOpen(isOpen);

          if (!isOpen) closeModal();
        }}
        css={[
          isWideFormat &&
            css`
              width: 100%;
              max-width: unset;
              height: 100%;
              max-height: unset;
              border-radius: 0;
            `,
        ]}
      >
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              height: 60px;
              position: relative;
              margin: ${isWideFormat ? '0 15vw' : '0'};
            `}
          >
            <div
              css={css`
                position: absolute;
                right: 10px;
                top: 10px;
                display: inline-flex;
                align-items: center;
              `}
            >
              <IconButton
                css={[
                  css`
                    margin-right: 12px;
                  `,
                  isMobileScreen &&
                    css`
                      visibility: hidden;
                    `,
                ]}
                title="Растянуть блок"
                onClick={() => setWideFormat(prev => !prev)}
              >
                {isWideFormat ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
              </IconButton>
              <IconButton title="Закрыть">
                <X onClick={closeModal} />
              </IconButton>
            </div>
          </div>
          <div
            css={css`
              overflow-y: auto;
              overflow-x: hidden;
              padding: ${isWideFormat ? '0 15vw' : '0 70px'};
              max-height: ${isWideFormat ? 'unset' : '75vw'};
              flex: 1;

              &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
                background-clip: padding-box;
                border-radius: 16px;
                width: 16px;
                height: 16px;
                border: 4px solid transparent;
              }

              &::-webkit-scrollbar {
                background-color: transparent;
              }
            `}
          >
            <Editor
              initialValue={{
                content: editorInitialValue?.content,
                title: editorInitialValue?.title,
              }}
              onChange={value => {
                if (value.state === 'done' && !articleKey) {
                  createMutation.mutate();
                }

                setEditorValue(prev => {
                  if (value?.state === 'saving')
                    return {
                      ...prev,
                      state: 'saving',
                    };

                  return value;
                });
              }}
              ref={editorRef}
            />
          </div>
          <ImportFile
            value={preview}
            css={css`
              padding: ${isWideFormat ? '0 15vw' : '0 70px'};
            `}
            accept="image/*"
            onChange={content => {
              if (!content?.[0]) return;
              setEditorValue(prev => ({ ...prev, state: 'done' }));
              setPreview(content[0]);
            }}
          >
            Выберите обложку для статьи
          </ImportFile>
          <div
            css={css`
              padding: ${isWideFormat ? '0 15vw' : '0 70px'};
              height: 70px;
              display: flex;
              align-items: center;
            `}
          >
            <Button
              disabled={
                (!preview && !editorValue?.title && !editorValue?.content?.blocks?.length && !editorInitialValue) ||
                createMutation.isError
              }
              onClick={() => {
                if (!editorValue || !articleKey) return;

                const parsedContent = JSON.stringify(editorValue.content);
                const myTtitle = editorValue.title ?? 'Без названия';

                articleMutaion.mutate(
                  {
                    title: myTtitle,
                    key: articleKey,
                    is_free: isFree,
                    content: parsedContent ?? '{}',
                    image_preview: preview?.content ?? '',
                    status: 'published',
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries('/drafts');
                      queryClient.invalidateQueries('/articles');

                      closeModal();
                    },
                  }
                );
              }}
              variant="filled"
              color="primary"
              size="large"
              css={css`
                margin-right: 12px;
              `}
            >
              Опубликовать
            </Button>
            <div
              css={css`
                display: flex;
                gap: 12px;
              `}
            >
              <IconButton
                disabled={
                  (!preview && !editorValue?.title && !editorValue?.content?.blocks?.length && !editorInitialValue) ||
                  !articleKey ||
                  createMutation.isError
                }
                variant="outlined"
                onClick={() => {
                  navigate(`/article/${articleKey}`);
                }}
                title="Перейти к статье"
                size="large"
                color="black"
              >
                <Eye size={20} />
              </IconButton>
              <IconButton
                disabled={
                  (!preview && !editorValue?.title && !editorValue?.content?.blocks?.length && !editorInitialValue) ||
                  !articleKey ||
                  createMutation.isError
                }
                onClick={() => {
                  if (!editorValue || !articleKey) return;

                  const parsedContent = JSON.stringify(editorValue.content);
                  const myTtitle = editorValue.title ?? 'Без названия';

                  publishMutaion.mutate(
                    {
                      title: myTtitle,
                      key: articleKey,
                      is_free: isFree,
                      content: parsedContent ?? '{}',
                      image_preview: preview?.content ?? '',
                      status: 'draft',
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries('/drafts');
                        queryClient.invalidateQueries('/articles');

                        closeModal();
                      },
                    }
                  );
                }}
                variant="outlined"
                title="Вернуть в черновики"
                size="large"
                color="black"
              >
                <Archive size={20} />
              </IconButton>
              <IconButton
                disabled={createMutation.isError}
                variant="outlined"
                title={`Сделать статью ${isFree ? 'платной' : 'бесплатной'}`}
                size="large"
                onClick={() => {
                  setFree(p => !p);
                  setEditorValue(prev => ({ ...prev, state: 'done' }));
                }}
              >
                <CircleDollarSign size={20} color={isFree ? 'black' : 'orange'} />
              </IconButton>
            </div>
            <Typography
              as={'span'}
              css={css`
                margin-left: 12px;
                display: flex;
                align-items: center;
              `}
            >
              {!createMutation.isError && (
                <>
                  {(editorValue?.state === 'saving' || articleMutaion.isLoading || createMutation.isLoading) && (
                    <>
                      <Loader
                        size={12}
                        css={css`
                          margin-right: 8px;
                        `}
                      />
                      Сохранение...
                    </>
                  )}
                  {(editorValue?.content?.blocks?.length || editorValue?.title) && editorValue?.state === 'done' && (
                    <>
                      <CheckCircle2
                        color="#00a88b"
                        size={18}
                        css={css`
                          margin-right: 4px;
                        `}
                      />
                      Сохранено
                    </>
                  )}
                </>
              )}
              {createMutation.isError && <Typography color="red">Ошибка сохранения шаблона</Typography>}
              {articleMutaion.isError && <Typography color="red">Ошибка сохранения шаблона</Typography>}
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
