import { LogLevels, ToolConstructable, ToolSettings } from '@editorjs/editorjs';
import { css } from '@emotion/react';
import { UNSAFE_IMPORTED_TOOLS } from 'components/Editor/plugins';

// @ts-expect-error Для некоторых плагинов типизация не предусмотрена, поэтому можно ослабить типы
const TOOLS = UNSAFE_IMPORTED_TOOLS as Record<string, ToolConstructable | ToolSettings>;

const RU_LOCALIZATION = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Нажмите, чтобы настроить',
          'or drag to move': 'или перетащите',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Конвертировать в',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Добавить',
        },
      },
    },
    toolNames: {
      Text: 'Параграф',
      Heading: 'Заголовок',
      List: 'Список',
      Warning: 'Примечание',
      Checklist: 'Чеклист',
      Quote: 'Цитата',
      Code: 'Код',
      Delimiter: 'Разделитель',
      'Raw HTML': 'HTML-фрагмент',
      Table: 'Таблица',
      Link: 'Ссылка',
      Marker: 'Маркер',
      Bold: 'Полужирный',
      Italic: 'Курсив',
      InlineCode: 'Моноширинный',
      Alert: 'Блок с текстом',
    },
    tools: {
      warning: {
        Title: 'Название',
        Message: 'Сообщение',
      },
      link: {
        'Add a link': 'Вставьте ссылку',
      },
      stub: {
        'The block can not be displayed correctly.': 'Блок не может быть отображен',
      },
      header: {
        'Heading 1': 'Первый уровень',
        'Heading 2': 'Второй уровень',
        'Heading 3': 'Третий уровень',
        'Heading 4': 'Четвертый уровень',
      },
      list: {
        Ordered: 'Пронумерованный',
        Unordered: 'Маркированный',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Удалить',
        'Click to delete': 'Точно удалить?',
      },
      moveUp: {
        'Move up': 'Переместить вверх',
      },
      moveDown: {
        'Move down': 'Переместить вниз',
      },
    },
  },
};

export const CUSTOM_THEME = css`
  .cdx-block,
  .ct,
  .ce-toolbar,
  .ce-popover,
  .ce-header,
  .cdx-alert-info,
  .ce-inline-toolbar,
  div {
    font-family: 'Manrope';
    color: #1a1a22;
  }

  span {
    font-family: 'Manrope' !important;
    font-size: 16px;
    line-height: 1.6em !important;
  }

  .cdx-alert {
    width: fit-content;
  }

  .ce-inline-toolbar {
    position: absolute;
    z-index: 99999;
  }

  .cdx-alert-primary {
    background-color: #f5f7fd;
    border-radius: 16px;
    border-color: transparent;
    padding: 16px;
    color: #1e1e1e;
  }

  .ce-toolbar__content,
  .ce-block__content {
    margin: unset;
  }

  .ce-block a {
    color: #00a88b;

    &:visited {
      color: #00a88b;
      text-decoration: none !important;
    }

    ul.cdx-block {
    }
  }
  li.cdx-list__item::marker {
    color: #00a88b;
  }

  .image-tool__caption {
    display: none;
  }

  .ce-block__content {
    max-width: unset;
  }

  .ce-inline-tool {
    padding: 6px 3px;
  }

  .ce-conversion-toolbar {
    width: 180px;
  }

  h2.ce-header {
    color: #00a88b;
    font-size: 20px;
    line-height: 28px;
  }

  h3.ce-header {
    color: #00a88b;
    font-size: 14px;
    line-height: 18.5px;
  }
`;

export const getEditorProps = () => {
  return {
    logLevel: 'ERROR' as LogLevels.ERROR,
    i18n: RU_LOCALIZATION,
    tools: TOOLS,
  } as const;
};
