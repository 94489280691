import CheckList from '@editorjs/checklist';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import Header from '@editorjs/header';
import { default as ImageTool } from '@editorjs/image';
import InlineCode from '@editorjs/inline-code';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Raw from '@editorjs/raw';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import Alert from 'editorjs-alert';
import ColorPlugin from 'editorjs-text-color-plugin';
import YoutubeEmbed from 'editorjs-youtube-embed';

export const UNSAFE_IMPORTED_TOOLS = {
  embed: Embed,
  table: Table,
  marker: Marker,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  raw: Raw,
  youtubeEmbed: YoutubeEmbed,
  header: {
    class: Header,
    config: {
      levels: [2, 3],
      defaultLevel: 2,
    },
  },
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  image: {
    class: ImageTool,
    config: {
      endpoints: {
        byFile: `${import.meta.env.VITE_API_HOST}/blog/static`,
        byUrl: `${import.meta.env.VITE_API_HOST}/blog/url-static`,
      }
    },
  },
  alert: {
    class: Alert,
    inlineToolbar: true,
    config: {
      defaultType: 'primary',
    },
  },
  color: {
    class: ColorPlugin,
    config: {
      colorCollections: ['#1A1A22', '#00a88b', '#73737F'],
      defaultColor: '#FF1300',
      type: 'text',
    },
  },
};
