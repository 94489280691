import { css, useTheme } from '@emotion/react';
import { logout } from 'api/user';
import { useCurrentUser } from 'hooks/user';
import { LogOut } from 'lucide-react';
import { useQuery, useQueryClient } from 'react-query';
import { useMedia } from 'react-use';
import { Avatar } from 'ui/Avatar';
import { Button } from 'ui/Button';
import { Typography } from 'ui/Typography';
import { PLATFORM_URL } from 'utils/host';

export const Header = () => {
  const [user, setUser] = useCurrentUser();
  const signOut = useQuery(['/user-logout'], logout, {
    enabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/user'] });
      setUser(null);
    },
  });
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isTabletScreen = useMedia('(max-width: 905px)');

  return (
    <div
      css={[
        css`
          padding: ${isTabletScreen ? '0 16px' : '12px 24px'};
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        `,
      ]}
    >
      <a
        css={css`
          display: inline-flex;
          text-decoration: none;
        `}
        href={`${PLATFORM_URL}/info`}
      >
        <img
          css={css`
            max-width: 157px;
            height: 56px;
            object-fit: contain;
          `}
          src="/public/images/logo.png"
          alt="logo"
        />
      </a>
      {user && (
        <>
          <div
            css={[
              css`
                display: flex;
              `,
              isTabletScreen &&
                css`
                  order: 3;
                  width: 100%;
                  overflow-x: auto;
                  background-color: ${theme.colors['black-200']};
                  padding: 5px 24px;
                  margin: 0 -24px;
                `,
            ]}
          >
            <Button as={'a'} href={`${PLATFORM_URL}/info`} variant="link" color="accent">
              Сводка
            </Button>
            <Button as={'a'} href={`${PLATFORM_URL}/tests`} variant="link" color="accent">
              Тесты
            </Button>
            <Button as={'a'} href={`${PLATFORM_URL}/trainers`} variant="link" color="accent">
              Тренажеры
            </Button>
            <Button as={'a'} href={`${PLATFORM_URL}/map`} variant="link" color="accent">
              Карта роста
            </Button>
            <Button as={'a'} variant="filled" color="accent" href="/">
              Статьи
            </Button>
            {user.role === 'admin' && (
              <Button as={'a'} href={`${PLATFORM_URL}/admin`} variant="link" color="accent">
                Админка
              </Button>
            )}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Typography
              as={'a'}
              href={`${PLATFORM_URL}/info`}
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Avatar
                user={user}
                css={css`
                  margin-right: 12px;
                `}
              />
              <Typography
                as={'span'}
                color="gray"
                weight="bold"
                variant="body2"
                css={css`
                  margin-right: 24px;
                `}
              >
                {(user?.name)
                  .split(' ')
                  .map((el: string, i: number) => {
                    if (i === 1) {
                      return `${el[0]}.`;
                    }

                    return el;
                  })
                  .join(' ')}
              </Typography>
            </Typography>
            <LogOut color="#A7A9AF" style={{ cursor: 'pointer' }} onClick={() => signOut.refetch()} />
          </div>
        </>
      )}
    </div>
  );
};
