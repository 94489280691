import { ThemeProvider as EmotionThemeProvider, Global, css } from '@emotion/react';
import { ReactNode } from 'react';

export const defaultTheme = {
  font: 'Manrope',
  colors: {
    'black-900': '#1A1A22',
    'black-800': '#2E2E3A',
    'black-700': '#73737F',
    'black-600': '#8F8F99',
    'black-500': '#A9A9B3',
    'black-400': '#E0E2EB',
    'black-300': '#EEF0FA',
    'black-200': '#F5F7FD',
    'black-100': '#FBFCFF',
    'black-50': '#FFFFFF',
    pomegranate: '#730004',
    cherry: '#A31920',
    red: '#D93535',
    raspberry: '#EF6262',
    coral: '#FFAEB2',
    brown: '#753000',
    sienna: '#A54D12',
    orange: '#E1711D',
    cantaloupe: '#F18D4B',
    peach: '#FFB886',
    dijon: '#683C00',
    honey: '#9E6700',
    mustard: '#D19000',
    mango: '#F3B344',
    sand: '#FFCF7D',
    potato: '#665200',
    gold: '#8A7100',
    yellow: '#BF9F00',
    banana: '#EFCA43',
    lemon: '#FFE26C',
    army: '#4A6000',
    avocado: '#647D05',
    lime: '#7DA124',
    pear: '#ADD359',
    cucumber: '#D3F381',
    moss: '#005736',
    forest: '#037D48',
    green: '#09A854',
    kiwi: '#54C981',
    sprout: '#8AF0B5',
    pine: '#005D58',
    spruce: '#057A68',
    mint: '#00a88b',
    tiffany: '#5FCEB2',
    seafoam: '#86F0D9',
    ocean: '#00516C',
    teal: '#04677B',
    turquoise: '#239CAA',
    aqua: '#58C0D0',
    mist: '#EFF3F5',
    navy: '#00249C',
    royal: '#074DB6',
    blue: '#307FE2',
    blueberry: '#6FAEFC',
    sky: '#A2CFFF',
    night: '#2F2A95',
    damson: '#4D49BE',
    periwinkle: '#6A6DCD',
    lupine: '#979CE7',
    ice: '#C2C5FF',
    royalty: '#512179',
    aubergine: '#6D42A1',
    purple: '#9063CD',
    grape: '#B28FE0',
    pearl: '#DABDFF',
    passionfruit: '#7B0054',
    plum: '#991A6C',
    pink: '#C242A1',
    rose: '#DE6EC6',
    blush: '#FFADF8',
    white: '#FFFFFF',
  },
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${defaultTheme.colors.mist};
          }
        `}
      />
      <EmotionThemeProvider theme={defaultTheme}>{children}</EmotionThemeProvider>
    </>
  );
};
