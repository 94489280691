import { css, useTheme } from '@emotion/react';

export const Loader = ({ size = 20, ...restProps }: { size?: number }) => {
  const theme = useTheme()

  return (
    <div
      css={css`
        display: inline-block;
        width: ${size}px;
        height: ${size}px;
        border-color: ${theme.colors.mint};
        border-width: 2px;
        border-top-color: aliceblue;
        border-radius: 100%;
        animation: 0.8s spining infinite;
        border-style: solid;

        @keyframes spining{
          to {
            transform: rotate(1turn);
          }
        }
      `}
      {...restProps}
    />
  );
};
