import { useMedia } from "react-use";

const production = 'cvcode.ru';
const stage = 'stage.cvcode.ru';

export const isProduction = () => {
  return (
    !window.location.hostname.includes('stage') && !window.location.hostname.includes('localhost')
  );
};

export const hostOriginal =  isProduction() ? production : stage;
export const hostApiOriginal =  isProduction() ? `api.${production}` : `api.stage.cvcode.ru`;


export const PLATFORM_URL = (() => {
  if (import.meta.env.VITE_ENV === 'dev') return 'https://localhost:4200'
  if (import.meta.env.VITE_ENV === 'local') return 'https://localhost:4200'
  if (import.meta.env.VITE_ENV === 'staging') return 'https://stage.cvcode.ru'
  if (import.meta.env.VITE_ENV === 'production') return 'https://cvcode.ru'

  throw new Error("Не удаётся определить VITE_ENV")
})()

export const isMobileScreen = () => {
  return useMedia('(max-width: 600px)')
}